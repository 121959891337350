import { Method } from 'axios';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { endpoints, methods, request } from '../lib/api';

const PLURAL_LOOKUP = {
  categories: 'category',
  serviceTypes: 'service',
};

const useSearchTermPrediction = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const { data: configData } = useQuery<{ data: any }, Error>('config', async () => {
    const data = await request({ endpoint: endpoints.config, method: methods.GET as Method });

    return data.data.data;
  });

  const flatCategoriesAndServices = useMemo(() => {
    const flatData = configData?.config
      ? Object.keys(configData.config)
          .sort()
          .reduce((combinedOptions, optionKey) => {
            return [
              ...combinedOptions,
              ...configData.config[optionKey].map((item) => ({
                ...item,
                group: PLURAL_LOOKUP[optionKey as keyof typeof PLURAL_LOOKUP],
              })),
            ];
          }, [])
      : [];

    if (!searchTerm) return flatData;

    return flatData.filter((option) => option.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [searchTerm, configData]);

  const onInputChange = (searchValue: string) => {
    console.log('hook', searchTerm);
    setSearchTerm(searchValue);
  };

  return {
    onInputChange,
    predictions: flatCategoriesAndServices,
  };
};

export default useSearchTermPrediction;
