import { Image } from '@pata-app/core';
import { WidthContainer } from '@pata-app/ui-document';
import { Button } from '@pata-app/ui-form';

import React from 'react';

import { TextWithHighlight } from '../../../../components/textWithHighlight/textWithHighlight';
import { ActionContainer, HeroContent, HeroOverviewContainer, HeroSectionContainer } from './heroSection.styled';

const HeroSection = () => {
  const handleSignUpClick = () => {
    window.location.href = `${process.env.PARTNERS_BASE_URL}/sign-up`;
  };

  const handleBookDemo = () => {
    window.location.href = `https://meetings.hubspot.com/pata/demo`;
  };

  return (
    <HeroSectionContainer>
      <Image
        src={`${process.env.CDN_BASE_URL}/images/image-partners-app-ipad-iphone.png`}
        alt="Preview of Pata Partner app on iPad"
        height={2000}
        width={2000}
      />

      <WidthContainer noBackground>
        <HeroOverviewContainer>
          <HeroContent>
            <TextWithHighlight>
              <h1>
                Your trusted subscription-free <span>booking platform </span>
                for pet care businesses
              </h1>
            </TextWithHighlight>
            <p>
              Whether you’re a pet groomer, dog trainer, or any other pet service provider, Pata helps you manage your
              business efficiently, boost visibility, and grow the client base effortlessly.
              <br />
            </p>
            {/* <p>
              <strong>Sign up for free </strong>
              to transform your pet care business today.
            </p> */}

            <ActionContainer>
              <Button onClick={handleSignUpClick}>Join Pata</Button>
              <Button buttonType="secondary" onClick={handleBookDemo}>
                Book a demo
              </Button>
            </ActionContainer>
          </HeroContent>
          <Image
            src={`${process.env.CDN_BASE_URL}/images/image-phone-laptop-app.webp`}
            alt="Preview of Pata Partner app on iPad"
            height={2000}
            width={2000}
          />
        </HeroOverviewContainer>
      </WidthContainer>
    </HeroSectionContainer>
  );
};

export default HeroSection;
