import React, { useEffect, useRef, useState } from 'react';

import useOnClickOutside from '../../hooks/useClickOutside';
import groupDataByKey from '../../lib/utils/groupDataByKey';
import {
  AutoCompleteInput,
  FreeTextSearch,
  Prediction,
  PredictionGroupTitle,
  PredictionsContainer,
} from './autoComplete.styled';

export type TPrediction = {
  id: string;
  name: string;
};

interface IAutoComplete {
  value: string;
  onInputChange: (value: string) => void;
  predictions: Array<TPrediction>;
  onSelect: (value: any) => void;
  placeholder?: string;
  allowFreeText?: boolean;
  groupKey?: string;
  freeSearchDescription?: string;
  closeMenuOnSelect?: boolean;
  selectedValues?: Array<string>;
}

const AutoComplete = ({
  value,
  predictions,
  onInputChange,
  onSelect,
  placeholder,
  allowFreeText,
  groupKey,
  freeSearchDescription,
  closeMenuOnSelect = true,
  selectedValues = [],
}: IAutoComplete) => {
  const [input, setInput] = useState('');
  const [showAutoCompleteOptions, setShowAutoCompleteOptions] = useState(false);

  const containerRef = useRef();

  console.log({ value });

  useOnClickOutside(containerRef, () => {
    if (!!input) {
      setInput(value);
    } else {
      // onSelect({ name: '', group: 'name' });
    }
    setShowAutoCompleteOptions(false);
  });

  useEffect(() => {
    console.log('mounting');
  }, []);

  useEffect(() => {
    console.log('useEffect called', value);

    if (value) {
      setInput(value);
    }
  }, [value]);

  const predictionsByGroup = groupKey && groupDataByKey(predictions, groupKey);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('autoComplete', e.target.value);

    setInput(e.currentTarget.value);
    onInputChange(e.currentTarget.value);
  };

  const onInputFocus = () => {
    setShowAutoCompleteOptions(true);
  };

  const onInputBlur = () => {
    // setInput(value);
    // onInputChange(value);
  };

  const handlePredictionSelection = (prediction: TPrediction) => {
    onSelect(prediction);
    setInput(prediction.name);

    if (closeMenuOnSelect) setShowAutoCompleteOptions(false);
  };

  const handleFreeTextSearch = () => {
    setShowAutoCompleteOptions(false);

    onSelect({ name: input, group: 'name' });
  };

  console.log({ input });

  return (
    <div ref={containerRef}>
      <AutoCompleteInput
        value={input ?? undefined}
        placeholder={placeholder}
        onChange={handleInputChange}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
      />
      {/* {(!!predictions.length || (!!input && value !== input)) && showAutoCompleteOptions && ( */}
      {showAutoCompleteOptions && (
        <PredictionsContainer>
          {allowFreeText && !!input && (
            <FreeTextSearch onClick={handleFreeTextSearch}>
              <span>{`${freeSearchDescription} including: `}</span>
              <span>{input}</span>
            </FreeTextSearch>
          )}

          {!groupKey && (
            <>
              {predictions.map((prediction) => (
                <Prediction
                  isSelected={selectedValues.includes(prediction.id)}
                  key={prediction.id}
                  onClick={() => handlePredictionSelection(prediction)}
                >
                  <span>{prediction.name}</span>
                </Prediction>
              ))}
            </>
          )}

          {!!groupKey &&
            Object.keys(predictionsByGroup).map((group) => (
              <>
                <PredictionGroupTitle>{group}</PredictionGroupTitle>
                {predictionsByGroup[group].map((prediction) => (
                  <Prediction
                    isSelected={selectedValues.includes(prediction.id)}
                    key={prediction.id}
                    onClick={() => handlePredictionSelection(prediction)}
                  >
                    <span>{prediction.name}</span>
                  </Prediction>
                ))}
              </>
            ))}
        </PredictionsContainer>
      )}
    </div>
  );
};

export default AutoComplete;
